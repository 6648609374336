<template>
  <div>
    <main class="sub __contact __video">
      <div class="inner __faq">
        <div class="tit req-type">
          <h1>교육 동영상</h1>
          <p class="stit">동영상을 시청할 수 있습니다.</p>
        </div>
        <div class="sub-cont">
          <div class="guide-tapmenu-wrap">
            <ul style="cursor: pointer">
              <li>
                <router-link
                  :class="{ on: routeName === 'eduApply' }"
                  to="/eduApply"
                >
                  교육신청
                </router-link>
              </li>
              <li>
                <router-link
                  :class="{ on: routeName === 'videoWatch' }"
                  to="/video-watch"
                >
                  교육 동영상
                </router-link>
              </li>
              <li>
                <router-link
                  :class="{ on: routeName === 'resumeEdu' }"
                  to="/resume/edu"
                >
                  교육 이수 내역
                </router-link>
              </li>
            </ul>
          </div>
          <div id="mainVideo-con">
            <div id="mainVideo-worp">
              <div id="player"></div>
              <youtube
                :video-id="videoId"
                ref="video"
                id="mainVideo"
                :player-vars="playerVars"
                @click="yuoTubeUrl"
                @ready="ready"
                @playing="playing"
                @ended="ended"
                @paused="paused"
                @error="error"
              ></youtube>
            </div>
            <div class="mainTitle-con">
              <h2 class="mainTitle">{{ mainTitle }}</h2>
              <p class="subTitle">{{ subTitle }}</p>
              <div class="percentage">
                <div class="time-con">
                  <span class="percentage-time">{{ time }}</span
                  >&#09;&#47;&#09;
                  <span class="percentage-totalTime">{{ totalTime }}</span>
                </div>
                <div class="percentage-con">
                  <div class="percentage-Var" :style="percentageStyle"></div>
                  <span>{{ Math.floor(progress) + '%' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div id="video_list-con">
            <ul>
              <li
                class="video_list"
                v-for="youTube in youTube"
                :id="youTube.youTubeId"
                :key="youTube.Url"
                @click="yuoTubeUrl(youTube, $event)"
              >
                <div class="youTubeImg-con">
                  <img
                    :src="defaultUrl + youTube.youTubeId + imgResolution"
                    alt="썸네일"
                  />
                </div>
                <div class="youTubeText-con">
                  <div class="youTubeText">
                    <h3>{{ youTube.listTitle }}</h3>
                    <p>{{ youTube.listSubTitle }}</p>
                  </div>
                </div>
                <div class="icon-con">
                  <img
                    style="width: 100%"
                    src="~assets/image/ico/video_play_btn.svg"
                    alt=""
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import { getHogoo153EduApply, saveHogoo153EduCompl } from 'Api/modules'
Vue.use(VueYoutube)
export default {
  mixins: [],
  props: {},
  components: {},
  data() {
    return {
      defaultUrl: 'https://img.youtube.com/vi/',
      imgResolution: '/mqdefault.jpg',
      videoId: 'DsY71mgcERE',
      mainTitle: '(주)탑스맨 - 호구153을 소개합니다!',
      subTitle: '(주)탑스맨 - 호구153을 소개합니다!',
      hgEduId: 0,
      // playing: false,
      youTube: [
        {
          youTubeId: 'DsY71mgcERE',
          listTitle: '(주)탑스맨 - 호구153을 소개합니다!',
          listSubTitle: '(주)탑스맨 - 호구153을 소개합니다.',
          listHgEduId: 0,
        },
        {
          youTubeId: 'xiyJ-0oo4ec',
          listTitle: '(주)탑스맨(호구153)_호텔 구인구직 플랫폼 [1탄]',
          listSubTitle: '호구153을 소개합니다. [1탄]',
          listHgEduId: 0,
        },
        {
          youTubeId: 'c8035ziqNcs',
          listTitle: '(주)탑스맨(호구153)_호텔 구인구직 플랫폼 [2탄]',
          listSubTitle: '호구153을 소개합니다. [2탄]',
          listHgEduId: 0,
        },
        {
          youTubeId: 'gXhNKl707U8',
          listTitle: '1강 직업의식',
          listSubTitle: '쉼의 자리를 만들어 가는 사람들 룸메이드',
          listHgEduId: 7,
        },
        {
          youTubeId: '1J4YPB1fVyE',
          listTitle: '2강 호텔산업의 이해',
          listSubTitle: '호텔산업의 발전 / 한국 호텔산업의 역사',
          listHgEduId: 8,
        },
        {
          youTubeId: 'Bq9OJSFgrJc',
          listTitle: '3강 룸 메이드의 역할과 전망',
          listSubTitle: '호텔의 룸메이드는 숨은 진주와도 같다',
          listHgEduId: 9,
        },
        {
          youTubeId: '_GuuOY9r4co',
          listTitle: '4강 직무교육 호텔용어',
          listSubTitle: '호텔 용어, 같이 공부해보아요!',
          listHgEduId: 10,
        },
        {
          youTubeId: 'otP6ciZjHdE',
          listTitle: '5강 직무교육 객실정비방법',
          listSubTitle: '필수로 알아야 할! 객실 정비 순서',
          listHgEduId: 11,
        },
        {
          youTubeId: 'a6G3Q7HHLQs',
          listTitle: '6강 직무교육 베딩방법및 화장실청소',
          listSubTitle: '필수로 알아야 할! 객실 침구 정리',
          listHgEduId: 12,
        },
        {
          youTubeId: '8YLzpHunfTs',
          listTitle: '7강 호텔서비스',
          listSubTitle: '호텔은 서비스다!',
          listHgEduId: 13,
        },
        {
          youTubeId: 'H4MG6d9Hbdk',
          listTitle: '8강 진실의 순간',
          listSubTitle: '고객접정 MOT(고객 접점의 순간)',
          listHgEduId: 14,
        },
        {
          youTubeId: '5MZUEXTtkhk',
          listTitle: '9강 룸 메이드의 기본소양',
          listSubTitle: '룸메이드가 지켜야 할 에티켓',
          listHgEduId: 15,
        },
        {
          youTubeId: 'bB7sWUKsKXI',
          listTitle: '10강 불만고객 대응법',
          listSubTitle: '서비스는 곱셉의 법칙이 적용된다',
          listHgEduId: 16,
        },
      ],
      scrollTop: 0,
      target: null,
      video: document.getElementById('mainVideo'),
      time: '00:00',
      progress: 0,
      processId: null,
      preHgEduIdId: null,
      totalTime: null,
    }
  },
  watch: {},
  computed: {
    playerVars() {
      return {
        controls: 0,
        player3: Object,
        rel: 0,
        autoplay: 1,
        enablejsapi: 1,
        fs: 0,
        //  controls: 0,
        playsinline: 1,
        ivLoadPolicy2: 1,
        //playlist: this.videoId,
        //muted: 1,
        //loop: 1,
        start: this.start,
        end: this.end,
      }
    },
    percentageStyle() {
      return 'width:' + Math.floor(this.progress) + '%;'
    },
  },
  mounted() {
    this.target = document.querySelector('.container')
    this.target.addEventListener('scroll', this.handleScroll)

    var tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    var firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  },
  methods: {
    yuoTubeUrl(youTube, event) {
      this.$nextTick(() => {
        var Urltarget = event.currentTarget
        var youTubeUrl = Urltarget.getAttribute('id')
        var targetText = youTube.listTitle
        var targetSubText = youTube.listSubTitle
        this.videoId = youTubeUrl
        this.mainTitle = targetText
        this.subTitle = targetSubText
        this.hgEduId = youTube.listHgEduId
        var target = document.querySelector('.container')
        target.scrollTop = 50
      })
    },
    handleScroll: function (e) {
      this.scrollTop = e.target.scrollTop
      // if (this.scrollTop > 400) {
      //   this.target = e.target.scrollTop(0)
      // }
    },
    async ready() {
      if (this.$route.name === 'videoWatch') {
        let totalTime = await this.$refs.video.player.getDuration()
        this.durationTime(totalTime)
      }
      return false
    },
    async playing() {
      //확인
      if (this.$route.name === 'videoWatch') {
        let totalTime = await this.$refs.video.player.getDuration()
        this.durationTime(totalTime)
        // console.log('totalTime=', totalTime)
        this.$nextTick(() => {
          console.log('동영상 시작!', this.hgEduId)
          if (this.hgEduId > 0 && this.preHgEduIdId != this.hgEduId) {
            this.apply(this.hgEduId)
          }
          this.preHgEduIdId = this.hgEduId
          this.processId = setInterval(() => {
            this.$refs.video.player.getCurrentTime().then(time => {
              let progress = (time / totalTime) * 100

              this.progress = progress < 100 ? progress : 100
              // console.log('progress=', this.progress)
              this.updateTime(time + 1)
            })
          }, 100)
        })
      }
      return false
    },
    updateTime(time) {
      time = Math.round(time)
      let minutes = Math.floor(time / 60)
      let seconds = time - minutes * 60

      seconds = seconds < 10 ? '0' + seconds : seconds
      minutes = minutes < 10 ? '0' + minutes : minutes

      this.time = minutes + ':' + seconds
      // console.log('Time=', this.time)
    },
    durationTime(time) {
      time = Math.round(time)
      let minutes = Math.floor(time / 60)
      let seconds = time - minutes * 60

      seconds = seconds < 10 ? '0' + seconds : seconds
      minutes = minutes < 10 ? '0' + minutes : minutes

      this.totalTime = minutes + ':' + seconds
      // console.log('Time=', this.time)
    },
    async apply(hgEduId) {
      try {
        await getHogoo153EduApply({ hgEduId: hgEduId })
        console.log('신청이 완료되었습니다.', hgEduId)
      } catch (e) {
        console.error(e)
      }
    },
    async ended() {
      //확인
      this.$nextTick(() => {
        console.log('동영상 재생 완료!', this.hgEduId)
        if (this.hgEduId > 0) {
          this.complete(this.hgEduId)
        }
      })
    },
    async complete(hgEduId) {
      try {
        await saveHogoo153EduCompl({ hgEduId: hgEduId })
        console.log('교육 시청이 완료되었습니다.', hgEduId)
      } catch (e) {
        console.error(e)
      }
    },
    async paused() {
      //확인
      this.$nextTick(() => {
        console.log('정지', this.hgEduId)
        clearInterval(this.processId)
      })
    },
    buffering() {
      //확인
      this.$nextTick(() => {
        console.log('buffering')
      })
    },
    error() {
      //?
      this.$nextTick(() => {
        console.log('error')
      })
    },
  },
  async created() {},
}
</script>

<style lang="scss">
.percentage-Var {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 15%,
    transparent 15%,
    rgba(255, 255, 255, 0.2) 30%,
    transparent 30%,
    rgba(255, 255, 255, 0.2) 45%,
    transparent 45%,
    rgba(255, 255, 255, 0.2) 60%,
    transparent 60%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    rgba(255, 255, 255, 0.2) 90%,
    transparent 90%
  );
}
</style>
